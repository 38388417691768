import axios from 'axios'
// import router from '@/router/routers'
import { Dialog, Toast } from 'vant'
import {tokenStore,logout} from '../store/token'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api 的 base_url
  timeout: 10000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    const token = tokenStore.get();
    if (token) {
      config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    Toast.clear();
    const code = response.status
    if (code < 200 || code > 300) {
      Toast.fail(response.message)
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    Toast.clear();
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Toast.fail('请求超时，请重试')
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        Toast.fail('网络请求错误')
        return Promise.reject(error)
      }
    }
    if (code === 401) {
        Dialog.confirm({
            title:'提示',
            message:'登录过期，点击确定重新登录'
        }).then(() => {
          logout();
        })
    } else if (code === 403) {
        Dialog.alert({
            title: '提示',
            message: '无此功能访问权限',
        }).then(() => {});
    } else {
      const errorMsg = error.response.data.message
      console.error(errorMsg);
      Toast.fail("服务器错误")
    }
    return Promise.reject(error)
  }
)
export default service