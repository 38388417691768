import {logout,loginUrlSotre} from "./token"
import {allinpayUser} from './allinpay'
import {Dialog} from 'vant'

//登录信息
export const userInfo = {
  set:(user) => {
    window.sessionStorage.setItem('provider',JSON.stringify(user))
    console.log('provider',user);
    allinpayUser.set({userId:user.id,userSource:1,providerId:user.providerId})
  },
  remove:()=>{
    window.sessionStorage.removeItem('provider')
  },
  get:() => {
    var user = window.sessionStorage.getItem('provider')
    if(user){
      return JSON.parse(user)
    }else{
      loginUrlSotre.set('/mAgentHome');
      Dialog.alert({
        title: '提示',
        message: '请登录',
      }).then(() => {
        logout();
      });
    }
  },
  update:function(info){
    var u = this.get();
    if(u){
      var n = {...u,...info};
      this.set(n)
      return n;
    }else{
      throw new Error("login user is null");
    }
  }
}
//加盟商信息
export const providerInfo = {
  set:(info)=>{
    window.sessionStorage.setItem('providerInfo',JSON.stringify(info))
  },
  remove:()=>{
    window.sessionStorage.removeItem('providerInfo')
  },
  get:()=>{
    var user = window.sessionStorage.getItem('providerInfo')
    if(user){
      return JSON.parse(user)
    }else{
      return null
    }
  }
}
export const indexActive = {
  set:(index)=>{
    window.sessionStorage.setItem('indexActive',index);
  },
  get:()=>{
    return window.sessionStorage.getItem('indexActive')||'1';
  }
}
export const providerCarpartOrder = {
  province: '',
  city: '',
  country: '',
  providerId:'',
  fieldValue:'点击选择当前地区',
  total_price:0,
  number:0,
  details: []
 };
