
import {userInfo,providerInfo} from './provider'
import {allinpayUser} from './allinpay'
import {pricingUser} from './pricing'
import {personalUser} from './personal'
import Cookies from 'js-cookie'
export const tokenStore = {
  set: (token) => {
    Cookies.set('token',token)
    window.localStorage.setItem('token',token)
  },
  get:() => {
    var token = window.localStorage.getItem('token')
    if(!token){
      token = Cookies.get('token');
    }
    return token;
  },
  remove:()=>{
    window.localStorage.removeItem('token')
    Cookies.remove('token')
  }
}
export const loginUrlSotre = {
  set:(url)=>{
    window.sessionStorage.setItem('sysUrl',url)
  },
  get:()=>{
    return window.sessionStorage.getItem('sysUrl')||'/magenthome'
  }
}
export function logout(){
  tokenStore.remove();
  userInfo.remove();
  providerInfo.remove();
  allinpayUser.remove();
  pricingUser.remove();
  personalUser.remove();
  var url = loginUrlSotre.get();
  window.location.href = url;
}
// export const susUser = {
//   get:()=>{
//       return   
//   }
// }