export const allinpayUser = {
  set:(info)=>{
    //{userId:loginUser.id,userSource:1,newUser:true,mobile:'',memberType:'',hasSign:'',allinPayUserStats:'',providerId:''} userSource:1扳手，2爱拆车
    window.sessionStorage.setItem('allinpayUser',JSON.stringify(info))
  },
  get:()=>{
    var user = window.sessionStorage.getItem('allinpayUser')
    if(user){
      return JSON.parse(user)
    }else{
      return undefined
    }
  },
  remove:()=>{
    window.sessionStorage.removeItem("allinpayUser");
  },
  update:function(info){
    var u = this.get();
    console.log('allinpay update before',u);
    console.log('allinpay update data',info);
    if(!u){u = {};  }
    var n = {...u,...info};
    this.set(n)
    return n;
  }
}