import {logout,loginUrlSotre} from "./token"
import {Dialog} from 'vant'

//登录信息
export const pricingUser = {
  set:(user) => {
    window.localStorage.setItem('pricingUser',JSON.stringify(user))
  },
  remove:()=>{
    window.localStorage.removeItem('pricingUser')
  },
  get:() => {
    var user = window.localStorage.getItem('pricingUser')
    if(user){
      return JSON.parse(user)
    }else{
      loginUrlSotre.set('/pricingLogin');
      Dialog.alert({
        title: '提示',
        message: '请登录',
      }).then(() => {
        logout();
      });
    }
  },
  update:function(info){
    var u = this.get();
    if(u){
      var n = {...u,...info};
      this.set(n)
      return n;
    }else{
      throw new Error("login user is null");
    }
  }
}
export const pricingActive = {
  set:(active)=>{
    window.sessionStorage.setItem('pricingActive',active)
  },
  get:()=>{
    return window.sessionStorage.getItem('pricingActive')
  }
}
