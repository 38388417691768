import {logout,loginUrlSotre} from "./token"
import {allinpayUser} from './allinpay'
import {Dialog} from 'vant'

//登录信息
export const personalUser = {
  set:(user) => {
    allinpayUser.set({userId:user.id,userSource:2})
    window.sessionStorage.setItem('personalUser',JSON.stringify(user))
  },
  remove:()=>{
    window.sessionStorage.removeItem('personalUser')
  },
  get:() => {
    var user = window.sessionStorage.getItem('personalUser')
    if(user){
      return JSON.parse(user)
    }else{
      loginUrlSotre.set("/personal")
      Dialog.alert({
        title: '提示',
        message: '请登录',
      }).then(() => {
        logout();
      });
    }
  },
  update:function(info){
    var u = this.get();
    if(u){
      var n = {...u,...info};
      this.set(n)
      return n;
    }else{
      throw new Error("login user is null");
    }
  }
}
export const personalActive = {
  set:(active)=>{
    window.sessionStorage.setItem('personalActive',active)
  },
  get:()=>{
    return window.sessionStorage.getItem('personalActive')
  }
}
export const balanceUser = {
  get:()=>{
    return window.sessionStorage['balanceFlag']
  },
  set:(flag)=>{
    window.sessionStorage.setItem('balanceFlag',flag);
  }
}
